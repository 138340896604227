.mainPage {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mainPageContainer {
  /* margin-top: 30vh; */
  background-color: transparent;
  z-index: 2;
}

.coverTitle {
  background-image: url("../../../public/images/SjPhoto1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  width: 100%;
  height: 55vh;
  user-select: none;
  flex-direction: column;
}

.coverTitle h1 {
  font-family: sandBrush;
  text-shadow: 4px 4px 7px rgba(0, 0, 0, 0.6);
  margin-top: 8vh;
  color: goldenrod;
  font-size: 10.5em;
}

.coverTitle p {
  color: ivory;
  text-shadow: 4px 4px 7px rgba(0, 0, 0, 0.6);
  font-size: 1.7em;
  font-family: "Work Sans", sans-serif;
}

.coverTitle button {
  width: 15vw;
  margin: auto;
}

.startButton {
  color: ivory;
  z-index: 3;
  background-color: goldenrod;
  border: #000000c1 #222222;
  border-radius: 10px;
  box-shadow: -8px 10px 115px -24px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: -8px 10px 115px -24px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: -8px 10px 115px -24px rgba(0, 0, 0, 0.8);
  padding: 1vw;
  font-size: 1.5rem;
  font-family: "Work Sans", sans-serif;
  width: 15vw;
  margin-top: 3vh !important;
  transition: 0.2s ease-in-out;
  animation: bounce;
  animation-duration: 2s;
}

.startButton:hover {
  background-color: #222222;
  border: goldenrod solid thick;
  transition: 0.2s ease-in-out;
  animation: bounce;
  animation-duration: 2s;
  color: goldenrod;
}

.contentContainer {
  background: ivory;
  z-index: 2;
  display: flex;
  flex-direction: row;
}

.contentField {
  width: 50%;
  height: 10;
  z-index: 2;
  display: flex;
  justify-content: space-evenly;
  transition: 0.3s;
}

.contentField:nth-of-type(1) {
  width: 50%;
  background-color: ivory;
  height: 15vh;
  z-index: 2;
}

.contentField:nth-of-type(1):hover {
  width: 50%;
  background-color: goldenrod;
  height: 25vh;
  z-index: 2;
}

.contentField:nth-of-type(2) {
  width: 50%;
  background-color: goldenrod;
  height: 10vh;
  z-index: 2;
}

.contentField:nth-of-type(2):hover {
  width: 50%;
  height: 20vh;
  z-index: 2;
}

.contentItem {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.contentItem:nth-of-type(3) {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-right: 3vw;
}

.contentItem svg {
  height: 4vh;
  width: 8vw;
  margin: auto;
}

.contentField:nth-of-type(2) svg {
  fill: white;
}

.contentField:nth-of-type(2) .contentItem {
  width: 50%;
  z-index: 2;
  display: flex;
  flex-direction: row;
}

.contentItem svg:hover {
  height: 5vh;
  transform: scale(1.2);
  transition: 0.3s;
}

.contentItem h1 {
  font-size: 2em;
}

.contentItem a {
  font-size: 0.8em;
  text-align: center;
  width: 100%;
}

.contentBody {
  background-color: #222222;
  color: ivory;
  height: 30vh;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.buzz {
  font-size: 5em;
  font-family: sandBrush;
}

@media only screen and (max-width: 428px) {
  .coverTitle h1 {
    font-family: sandBrush;
    text-shadow: 4px 4px 7px rgba(0, 0, 0, 0.6);
    margin-top: 0vh;
  }

  .coverTitle p {
    color: ivory;
    text-shadow: 4px 8px 7px rgba(0, 0, 0, 0.8);
    font-size: 1.7em;
    font-family: "Work Sans", sans-serif;
  }
  .startButton{
    font-size: 2em;
    width: 5em !important;
  }


  .contentItem h1 {
    font-size: 1.5em;
  }
  
  .contentItem a {
    font-size: 0.5em;
    text-align: center;
    width: 100%;
  }
  .contentItem svg:nth-of-type(1) {
    height: 4vh;
    width: 4vw;
    margin: auto;
  }
  .locationSvgs svg{
    height: 5vh !important;
  }

  .locationSvgs{
    margin-right: 2vw;
  }


}

@media only screen and (max-width: 626px) {

  .coverTitle h1 {
    font-family: sandBrush;
    text-shadow: 4px 4px 7px rgba(0, 0, 0, 0.6);
    margin-top: 0vh;
  }

  .startButton{
    font-size: 1.5em;
    width: 5em !important;
  }
}


@media only screen and (max-width: 573px) {
  .contentItem h1 {
    font-size: 1.2em;
  }
  
  .contentItem a {
    font-size: 0.6em !important;
    text-align: center;
    width: 100%;
  }
}

@media only screen and (max-width: 948px) {
  .contentItem h1 {
    font-size: 1.2em;
  }
  
  .contentItem a {
    font-size: 0.5em !important;
    text-align: center;
    width: 100%;
  }
}