.navStyle{
    background-color: rgba(49, 144, 227, 0) !important; /* updates color of navigation background */
    color: goldenrod;
    z-index: 3 !important;
    backdrop-filter: blur(2px);
}



.navStyle ul{
    background-color: rgba(255, 255, 255, 0); /* updates color of navigation links container */
    
}

.navStyle li:hover{
    cursor: pointer;
    color: goldenrod
}


.navStyle a{
    color: ivory; /* updates color of navigation links text */
    text-shadow: 4px 4px 7px rgba(0,0,0,0.6);
}

.navStyle a:hover{
    color: goldenrod;
    transition: .3s ease-in-out
}


.sjLogo{
    font-family: sandBrush;
    font-size: 4em;
    color: goldenrod;
}

@media only screen and (max-width: 428px) {
    .sjLogo {
      display: none;
    }
  }

  @media only screen and (max-width: 700px) {
    .sjLogo {
      display: none;
    }
  }
