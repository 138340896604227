.aboutPage {
  display: flex;
  flex-direction: column;
  background-color: ivory;
  animation: fadeIn 3s;
}

.aboutTitle {
  width: 100%;
  height: 20vh;
  padding: auto;
  display: flex;
  align-items: center;
}

.aboutTitle h1 {
  font-size: 7.5em;
  color: goldenrod;
  width: 100%;
}

.aboutContent {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.aboutItem {
  width: 50%;
  background-color: ivory;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutItem h1 {
  color: rgb(0, 0, 0);
  margin: auto;
  font-size: 5.5em;
  color: goldenrod;
  width: 100%;
  height: 45%;
  padding-top: 5%;
}

.aboutItem p {
  color: 2222222;
  margin: auto;
  width: 70%;
  height: 100%;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 2em;
}

@media only screen and (max-width: 428px) {
  .aboutItem:nth-of-type(1) {
    width: 0%;
    display: none;
    background-color: ivory;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .aboutItem:nth-of-type(2) {
    width: 100%;
    background-color: ivory;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 626px) {
  .aboutItem p {
    color: 2222222;
    margin: auto;
    width: 70%;
    height: 80%;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 1.5em;
  }

  .aboutItem:nth-of-type(1) {
    width: 0%;
    display: none;
    background-color: ivory;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .aboutItem:nth-of-type(2) {
    width: 100%;
    background-color: ivory;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
