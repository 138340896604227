.carousel-container {
  width: 100%;
  position: relative;
}

.carousel-inner {
  display: flex;
}

.carousel-item {
  min-width: 100%;
  transition: transform 1s ease-in-out;
}
