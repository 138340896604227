.contactPage {
  min-height: 100vh;
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactTitle {
}

.contactTitle h1 {
  font-size: 10em !important;
  color: white;
  margin-top: 5vh;
}

.contactBlurb {
  color: white;
}

.contactPageBody {
  width: 75%;
  border: 40px double #daa520;
  background-color: #222222;
  border-radius: 10px;
}

.contactFormContainer {
  height: 60vh;
}

.contactFormContainer input {
  margin-top: 4vh;
  width: 50%;
  outline: none;
  border: none;
  outline-color: transparent;
  background-color: #222222;
  border-bottom: solid ivory;
  transition: 0.5s ease-in-out;
  color: ivory;
  font: size 2.5em;
}

.contactFormContainer input:focus {
  outline: none;
  outline-color: transparent;
  transition: 0.5s ease-in-out;
}

.contactFormContainer textarea {
  width: 50%;
  border: none;
  margin-top: 4vh;
  outline-color: transparent;
  background-color: #222222;
  border-bottom: solid ivory;
  transition: 0.5s ease-in-out;
  color: ivory;
}

.contactFormContainer textarea:focus {
  outline: none !important;
  outline-color: transparent;
}

input:focus,
textarea:focus,
select:focus,
.emailInput:focus,
.nameInput:focus {
  outline: none !important;
  box-shadow: none;
  border-bottom: solid goldenrod;
}

.sendButton {
  background-color: #daa520;
  color: ivory;
  border-radius: 5px;
  margin-top: 5vh;
  padding: 0.5em 1em;
}

@media only screen and (max-width: 428px) {
  .contactTitle h1 {
    font-size: 6em !important;
    color: white;
    margin-top: 5vh;
  }

  .contactPageBody {
    width: 100% !important;
    border: 20px double #daa520;
    background-color: #222222;
    border-radius: 10px;
  }

  .contactPage {
    min-height: 100vh;
    background-color: #222222;
    display: flex;
  }

  .contactFormContainer input {
    margin-top: 4vh;
    width: 60%;
    outline: none;
    border: none;
    outline-color: transparent;
    background-color: #222222;
    border-bottom: solid ivory;
    transition: 0.5s ease-in-out;
    color: ivory;
    font: size 2.5em;
  }

  .contactFormContainer textarea {
    width: 60%;
    border: none;
    margin-top: 4vh;
    outline-color: transparent;
    background-color: #222222;
    border-bottom: solid ivory;
    transition: 0.5s ease-in-out;
    color: ivory;
  }
}
